import React from 'react'
import Checkout from '../components/form/Checkout'
import Sectionbar from '../components/ui/Sectionbar'
import Navbar from '../components/ui/Navbar'
import { Box } from '@mui/material'
import { Container } from '@mui/material'
import Signup from './Signup'
import Login from './Login'
import { MainContent } from './MainContent'
import { useState, useMemo } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ColorModeContext } from '../contexts/ColorModeContext'
import Grid from '@mui/material/Grid'
import { SectionQuiz } from './SectionQuiz'
import Subscribe from '../components/ui/Subscribe'
import Account from '../components/ui/Account'

export default function Dashboard() {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [darkmode, setDarkmode] = useState('light');
  const [darkModeChecked, setDarkModeChecked] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [displayQuiz, setDisplayQuiz] = useState(false);
  const [quizTopic, setQuizTopic] = useState(false);
  const [contentType, setContentType] = useState('reading');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setDarkmode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
      },
    }),
    [],
  );

  const theme = useMemo(() => createTheme({palette:{ mode: darkmode }}));

  const quizCallBack = (topic) => {
    setDisplayQuiz(true);
    setQuizTopic(topic);
  }

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Navbar setHeight={setNavbarHeight} setDarkmode={setDarkmode} setOpenSignup={setOpenSignup} setOpenLogin={setOpenLogin} 
            setOpenSubscribe={setOpenSubscribe} darkModeChecked={darkModeChecked} setDarkModeChecked={setDarkModeChecked} setOpenAccount={setOpenAccount}/>
          <Grid container spacing = {0}>
              <Grid item xs={0} s={2} md={2}>
                <Sectionbar navbarHeight={navbarHeight} quizCallBack={quizCallBack} contentType={contentType} setContentType={setContentType} setOpenSignup={setOpenSignup} setOpenSubscribe={setOpenSubscribe}/>
              </Grid>
              <Grid item xs={11} s={8} md={8}>
                <Container style={{marginTop: 80}} className="MainContent">
                  <MainContent contentType={contentType}/>
                  <Signup openSignup={openSignup} setOpenSignup={setOpenSignup} setOpenLogin={setOpenLogin}/>
                  <Login openLogin={openLogin} setOpenLogin={setOpenLogin} setOpenSignup={setOpenSignup}/>
                </Container>
              </Grid>
              <Grid item xs={1} s={2} md={2}>
              </Grid>
            </Grid>
        </ThemeProvider>
        {openSubscribe ? <Subscribe open={openSubscribe} setOpen={setOpenSubscribe}/> : ""}
        {displayQuiz ? <SectionQuiz topic={quizTopic} open={displayQuiz} setOpen={setDisplayQuiz} /> : ""}
        {openAccount ? <Account open={openAccount} setOpen={setOpenAccount} /> : ""}
      </ColorModeContext.Provider>
    </>
  )
}

/*
<SectionQuiz topic={"Introduction"} />
            */
 
/*
      <Box
      sx={{
        width: 300,
        height: 300,
        backgroundColor: 'primary.dark',
        '&:hover': {
          backgroundColor: 'primary.main',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    />
    */