//import Quiz from "../components/form/Quiz";
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { Tooltip } from "@mui/material";
import { Alert, Modal, Box, Typography } from "@mui/material";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { setDoc, updateDoc, arrayUnion, doc } from "firebase/firestore"; 
import { useAuth } from "../contexts/AuthContext"
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const quiz_info = require('../resources/quiz.json')

const modalStylePassed = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #2e7d32',
  boxShadow: 24,
  p: 4,
  color: '#2e7d32'
};

const modalStyleFailed = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #d32f2f',
  boxShadow: 24,
  p: 4,
  color: '#d32f2f'
}


const Quiz = ({quiz_question, handleCallBack}) => {
  const question_name = quiz_question.question //string
  const answers = Object.entries(quiz_question.possible_answers).map(([k,v]) => `${v}`); //list of strings, note js keeps order of keys
  const ans = quiz_question.possible_answers[quiz_question.answer] //string
  const diff = quiz_question.difficult //boolean
  const reas = quiz_question.reason //nullable string

  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [buttonText, setButtonText] = useState('Submit Answer')
  const [complete, setComplete] = useState(false)
  const [passed, setPassed] = useState(true)
  const [passedSX, setPassedSX] = useState(modalStylePassed)

  const handleRadioChange = (event) => {
    setValue((event.target).value);
    setHelperText('');
    setError(false);
  };

  const handleClose = (event) => {
    setComplete(false);
    handleCallBack(passed);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === ans) {
      setHelperText('Correct! 😊');
      setError(false);
    } else {
      setHelperText('Sorry, wrong answer 😔');
      setPassed(false);
      setPassedSX(modalStyleFailed)
      setError(true);
    }

    setComplete(true);
  }

  return (
    <>
     <form onSubmit={handleSubmit}>
     <FormControl sx={{ m: 3 }} error={error} variant="standard">
     <FormLabel id="demo-error-radios">{question_name}{diff ? <Tooltip title="Hard Question!">🔥</Tooltip> : ""}
     </FormLabel>
        <RadioGroup
          aria-labelledby="demo-error-radios"
          name="quiz"
          value={value}
          onChange={handleRadioChange}
        >
          {answers.map((text, index) => (
            <FormControlLabel value={text} control={<Radio />} label={text} />
         ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
          {buttonText}
        </Button>
        </FormControl>
        <Modal
  open={complete}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={passedSX}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      {helperText}
    </Typography>
    {reas ? <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      {reas}
    </Typography> : ""}
    <Button sx={{ mt: 1, mr: 1 }} variant="outlined" onClick={handleClose}>
          Next Question
    </Button>
  </Box>
</Modal>
      </form>
    </>
  )
}

export const SectionQuiz = ({topic, open, setOpen }) => {
  const firebaseConfig = {
    apiKey: "AIzaSyAMSHGM207WyPb2eT5UbUE4N5lml-MlsQE",
    authDomain: "dengrco.firebaseapp.com",
    projectId: "dengrco",
    storageBucket: "dengrco.appspot.com",
    messagingSenderId: "970698864083",
    appId: "1:970698864083:web:926f111e7e16a842ecab26",
    measurementId: "G-7B0Y9GFLHH"  
  };

  const app = initializeApp(firebaseConfig);
  const fs = getFirestore(app);

    const { currentUser } = useAuth();
    const this_quiz = quiz_info["topics"][topic] //TODO pass to quiz form
    const [finished, setFinished] = useState(true); //if get to last question and still true, write to db
    const [failedNum, setFailedNum] = useState(0);
    let [currentQuestion, setCurrentQuestion] = useState(0);
    let quiz_questions = [];
    Object.entries(this_quiz).map(([k,v]) => {quiz_questions.push(v)});
    let [maxQuestions, setMaxQuestions] = useState(quiz_questions.length);
    let [finishedModal, setFinishedModal] = useState(false);
    let [finalScore, setFinalScore] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setCurrentQuestion(0);
      setFinished(true);
      setOpen(false);
      setFinishedModal(false);
    };

    const reset = () => {
      setCurrentQuestion(0);
      setFinished(true);
      setFinishedModal(false);
      setFailedNum(0);
      setOpen(true);
    };

    async function updateFirestore(topic) {
      if (currentUser) {
        const user = currentUser.uid; 
        await setDoc(doc(fs, 'app', 'users', user, 'quizProgress'), {
          topics: arrayUnion(topic)
        }, { merge: true});
      }
    }

    const nextQuestion = (passed) => {
      let failed = failedNum;

      if (!passed) {
        const nextFailedNum = failedNum + 1;
        failed = failedNum + 1;
        setFailedNum(nextFailedNum);
      }

      if (currentQuestion !== (maxQuestions - 1)) { 
        const nextNumber = currentQuestion + 1;
        setCurrentQuestion(nextNumber);
      } else {
        const fs = maxQuestions - failed
        let star = "❌"
        if (failed === 0) { 
          star = "⭐"
          updateFirestore(topic);
        }
        const fsString = star + fs + "/" + maxQuestions + star
        setFinalScore(fsString);
        setFinished(true);
        //TODO write to db
        setFinishedModal(true);
      }
    }
  
    return (
      <div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {topic} Knowledge Check
          </DialogTitle>
          <DialogContent>
            { finished ? 
              <Quiz key={currentQuestion} quiz_question={quiz_questions[currentQuestion]} handleCallBack={nextQuestion}/>
              : "" }
          </DialogContent>
          <DialogActions  sx={{ pt: 0, justifyContent: 'center' }}>
            <Button color="error" onClick={handleClose} autoFocus>
              Exit
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
  open={finishedModal}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={modalStylePassed}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      You have successfully completed the knowledge check for {topic}!
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Here is your score: {finalScore}
    </Typography>
    <Button sx={{ mt: 1, mr: 1 }} variant="outlined" onClick={reset}>
         Take Again
    </Button>
    <Button color="error" onClick={handleClose} autoFocus>
              Exit
            </Button>
    
  </Box>
</Modal>
      </div>
    );
  }
  