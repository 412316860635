import stripeLogo from '../../assets/stripeLogo.svg'
import React from 'react'
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {PaymentElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_cAXKzTcflOBDBqlK7EYPSfFs');

const steps = ['Select Your Plan', 'Checkout', 'Confirmation'];

const costs = {
    'Monthly': '$24',
    'Yearly': '$19',
    'Lifetime': '$350'
}

const costsDueToday = {
    'Monthly': '$24.00',
    'Yearly': '$228.00',
    'Lifetime': '$350.00'
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    border: '1px solid transparent',
    ":hover": {
        background: '#ebeced',
        border: '1px solid black'
    }
}));

const PlanStep = ({setStep, planType, setPlanType}) => {
    const [moZero, setMoZero] = useState(false);
    const [moOne, setMoOne] = useState(false);
    const [moTwo, setMoTwo] = useState(false);

    const handleMO = (event, itemNum) => {
        if (itemNum === 0) {
            setMoZero(true);
            setMoOne(false);
            setMoTwo(false);
        } else if ( itemNum === 1 ) {
            setMoZero(false);
            setMoOne(true);
            setMoTwo(false);
        } else {
            setMoZero(false);
            setMoOne(false);
            setMoTwo(true);
        }
    }
    //onMouseEnter={(event) => handleMO(event, 0)}
    const setPlanMonthly = () => {
        setPlanType('Monthly')
        setStep(1)
    }
    const setPlanYearly = () => {
        setPlanType('Yearly')
        setStep(1)
    }
    const setPlanLifetime = () => {
        setPlanType('Lifetime')
        setStep(1)
    }

    return (
        <>
        <div >Please select a plan type:</div>
        <Grid sx={{ marginTop:2, marginBottom: 3}} container spacing={2}>
  <Grid item xs={4} >
    <Item onClick={setPlanMonthly}><h1>Monthly</h1><h3>{costs['Monthly']} / Month</h3>Due Today: {costsDueToday['Monthly']}<br></br>
    </Item>
  </Grid>
  <Grid item xs={4}>
    <Item onClick={setPlanYearly}>
    <h1>Yearly</h1><h3>{costs['Yearly']} / Month</h3>Due Today: {costsDueToday['Yearly']}<br></br>
    </Item>
  </Grid>
  <Grid item xs={4}>
    <Item onClick={setPlanLifetime}>
    <h1>Lifetime</h1><h3>One Time Fee</h3>Due Today: {costsDueToday['Lifetime']}<br></br>
    </Item>
  </Grid>
</Grid>
        </>
    )
}

// this is needed as use elements requires a stripe context
const StripeWrapper = ({ setStep, planType}) => {
  const { currentUser } = useAuth();
  const stripe = useStripe();
  let mode_to_use = 'subscription'
  if (planType.toLowerCase === 'lifetime') {
    mode_to_use = 'payment'
  } 
  let elements = useElements({mode: mode_to_use});

  //const cardElement = elements.create('card');
  //cardElement.mount(#)

  async function createStripePayment(input_data) {

    const {submitError} = await elements.submit();
    if (submitError) {
      //TODO: handle this properly
      return false;
    }
    
    if (!currentUser) {
      return false
    }

    /*console.log('data is ' + JSON.stringify(csp_data))
    const intent = await fetch("https://us-west3-dengrco.cloudfunctions.net/create-stripe-payment?" + new URLSearchParams({
      "email": currentUser.email,
      "planType": planType.toLowerCase(),
      "fbAuthToken": currentUser.uid
    }), {
      method: "GET",
      //body: JSON.stringify(csp_data),
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
      }
    });
    */
    const csp_data = {
      "email": currentUser.email,
      "planType": planType.toLowerCase(),
      "fbAuthToken": currentUser.uid
    }
    /*
    const sesh = stripe.checkout.Session.create({
      customer_email: currentUser.email,
      line_items: [
        {
          "price": PRICES[planType.toLowerCase()],
          "quantity": 1,
        }
      ],
      mode: "payment",
  })
  */

  console.log('data is ' + JSON.stringify(csp_data))
  const intent = await fetch("https://us-west3-dengrco.cloudfunctions.net/create-stripe-payment", {
      method: "POST",
      body: JSON.stringify(csp_data),
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*'
      }
  });
  
    //const { clientSecret, stripeCustomerId } = await intent.json();
    
    const returned_data = await intent.json();
    const clientSecret = returned_data.clientSecret
    const stripeCustomerId = returned_data.stripeCustomerId
    console.log("awaited pi")
    console.log('retunred_data is ' + JSON.stringify(returned_data));
    console.log('pi and scid: ' + clientSecret + ", " + stripeCustomerId);
  
    // TODO: do i need to attach payment to customer first? guarantees subscription
    //TODO: disable payment button! this can stop duplicate payments. use disabled in button to do so

    /*const result = await stripe.confirmCardPayment(
      clientSecret
    );*/

     /* ({
      clientSecret
      ,confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      })};
      */

    /*if (result.error) {
      console.log('error is ' + JSON.stringify(result.error))
        alert("We're unable to process your card at this time. Please try a different one or contact support.");
        
        return false;
    }
    */
    const {error} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://app.dengr.co',
      },
    });

    if (error) {
      //TODO: handle error
      return false;
    }

    input_data['stripeCustomerId'] = stripeCustomerId
    const subscribed = await fetch("https://us-west3-dengrco.cloudfunctions.net/subscribe-user", {
        method: "POST",
        body: JSON.stringify(input_data),
        headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
        },
        mode: "no-cors"
    });
    console.log('subscribed is ' + subscribed)
    //TODO: add stripe input validation
    //TODO: handle error and return something
    //TODO: add coupons
  } 

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('event is ' + event)
    console.log('doc pf is ' + JSON.stringify(document))
    const response = await createStripePayment(event.data);
    setStep(2);
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="paymentForm">
        <PaymentElement />
        <Box textAlign='center' fullWidth>
          <Button sx={{marginTop: 3, fontSize: 20}} size="large" type="submit" disabled={!stripePromise} variant={'contained'} fullWidth>
            <b>Pay {costsDueToday[planType]}</b>
          </Button>
        </Box>
      </form>
    </>
  )
}

const CheckoutStep = ({setStep, planType}) => {

  const options = {
    mode: 'payment',
    currency: 'usd',
    // need to remove "$" as stripe is expecting numeric value
    // multiply by 100 as stripe does things in cents
    amount: Number(costsDueToday[planType].split("$")[1]) * 100,
  };

  /* use this if I need to have intent ready:
    useEffect(() => {
      const setSub = async () => {
        fetch('https://us-west3-dengrco.cloudfunctions.net/submit-payment?', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: emailInput.value,
          }),
        }).then(r => r.json());
      }
      setSub();
    }, []);
    */

    //<div style={{marginBottom: 10}}><h2>Secure Stripe Checkout</h2></div>
    return (
      <>
        <img align="right" style={{maxWidth: 120, marginTop: 10, marginLeft: 'auto'}} src={stripeLogo} className="Stripe-logo" alt="stripe-logo" />
        <Elements stripe={stripePromise} options={options}>
          <StripeWrapper setStep={setStep} planType={planType}/>
        </Elements>
      </>
    )
}

const ConfirmStep = () => {
    return (
        <div>Confirm Step</div>
    )
}

const StepResolver = ({step, setStep, planType, setPlanType}) => {
    let component = ""
    switch (step) {
        case 0: 
            component = <PlanStep setStep={setStep} planType={planType} setPlanType={setPlanType}/>
            break;
        case 1:
            component = <CheckoutStep setStep={setStep} planType={planType}/>
            break;
        case 2:
            component = <ConfirmStep />
            break;
    }
    return component;
}

const HorizontalLinearStepper = ({setFinished, setOpen}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [planType, setPlanType] = useState('Monthly');
  
    const isStepOptional = (step) => {
        const optionalSteps = [] //add optionals here, use the index in steps arr
        return optionalSteps.includes(step);
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    async function triggerAutoClose() {
        setFinished(true);
        await new Promise(resolve => setTimeout(resolve, 15000));
        setOpen(false);
    }

    useEffect(() => {
        if (activeStep === steps.length) {
            triggerAutoClose()
        }
    }, [activeStep])
  
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              <b>You've successfully subscribed to the course! Thank you!</b>
              <br />
              <br />
              We're excited to have you on board and are cheering for you to reach your Data Engineering goals.
              <br />
              <br />
              Feel free to exit this prompt or allow it to auto close momentarily. 
            </Typography>
          </>
        ) : (
          <>
            <Box sx={{ marginTop: 3}}>
                <StepResolver step={activeStep} setStep={setActiveStep} planType={planType} setPlanType={setPlanType} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              { /* disable button if on step 2 (index 1) because checkout has its own submit button */ }
              { activeStep != 1 ? 
              <Button onClick={handleNext} >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
              : 
              <Button disabled >Next</Button>
              }
            </Box>
          </>
        )}
      </Box>
    );
  }

export default function Subscribe({open, setOpen}) {
    const [finished, setFinished] = useState(false)

    function handleClose() {
        setOpen(false)
    }

    return (
      <>        
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Box sx={{ width: 600 }}>
            <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
              Subscribe
            </DialogTitle>
            <DialogContent>
              <HorizontalLinearStepper setFinished={setFinished} setOpen={setOpen}/>
            </DialogContent>
            <DialogActions  sx={{ pt: 0, justifyContent: 'center' }}>
              <Button color="error" onClick={handleClose} autoFocus>
                {finished ? "Exit" : "Cancel" }
              </Button>
            </DialogActions>
            </Box>
          </Dialog>
      </>
    )
}
