import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext'
//import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Modal } from '@mui/material';
import { useState } from 'react';

const ResetPasswordModal = ({openRSModal, setOpenRSModal}) => {
  const { resetPassword } = useAuth();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleOpen = () => {
    setOpenRSModal(true);
  };
  const handleClose = () => {
    setOpenRSModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const result = await resetPassword(data.get('email'));
      alert('A reset link has been sent to your email address. Please check your junk or spam folder if its not in your inbox. Contact support if it has not shown up within 10 minutes.');
      handleClose();
    } catch(err) {
      if (err["code"] && err["code"] == 'auth/wrong-password') {
        alert("Your password is incorrect. Please try again or reset your password using the link below.")
      } else if (err["code"] && err["code"] == 'auth/user-not-found') {
        alert("We weren't able to find your email. Please provide another or sign up if you haven't yet.")
      } else if (err["code"] && err["code"] == 'auth/invalid-email') {
        alert("Invalid email. Please use a differet one.")
      } else if (err["code"] && err["code"] == 'auth/missing-email') {
        alert("The email field is missing. Please provide a valid email address.")
      } else {
        alert("Sorry, we're unable to send you a password reset link at this time. Please try again later or send us a message using our contact form.")
        //TODO: log these errors to backend
      }
    }
  };


  return (
    <>
      <Modal
        open={openRSModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600 }} component="form" noValidate onSubmit={handleSubmit}>
          <h2 id="child-modal-title">Reset Password</h2>
          <p id="child-modal-description">
            What is the email associated with your account?
          </p>
          <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
          <Button type="submit" variant='contained' sx={{marginTop: 4}}>Submit</Button>
        </Box>
      </Modal>
    </>
  )
}

export default function Login({openLogin, setOpenLogin, setOpenSignup}) {
  const { login } = useAuth()
  //const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  const [openRSModal, setOpenRSModal] = useState(false);
  const handleClose = () => { setOpenLogin(false) };
  //let navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await login(data.get('email'), data.get('password'));
      setOpenLogin(false);
    } catch(err) {
      if (err["code"] && err["code"] == 'auth/wrong-password') {
        alert("Your password is incorrect. Please try again or reset your password using the link below.")
      } else if (err["code"] && err["code"] == 'auth/user-not-found') {
        alert("We weren't able to find your email. Please provide another or sign up if you haven't yet.")
      } else if (err["code"] && err["code"] == 'auth/invalid-email') {
        alert("Invalid email. Please use a differet one.")
      } else {
        alert("Sorry, we were unable to login you at this time. Please try again later or send us a message using our contact form.")
        //TODO: log these errors to backend
      }
    }
  };

  const handleSignup = () => {
    setOpenLogin(false);
    setOpenSignup(true);
  }

  const resetPassword = () => {
    setOpenRSModal(true);
  }

  return (
    <Modal
  open={openLogin}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Card>
    <CardContent>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link onClick={handleSignup} variant="body2" sx={{ 'cursor': 'pointer'}}>
                  Don't have an account yet? Sign up here!
                </Link>
              </Grid>
              <Grid item sx={{ 'marginTop': '1rem'}}>
                <Link onClick={resetPassword} variant="body2" sx={{ 'cursor': 'pointer'}}>
                  Trouble logging in? Click to reset password.
                </Link>
              </Grid>
            </Grid>
          </Box>
          <ResetPasswordModal openRSModal={openRSModal} setOpenRSModal={setOpenRSModal} />
        </Box>
      </Container>
      </CardContent>
      </Card>
      </Modal>
  );
}