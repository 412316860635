import React from 'react'
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Modal, Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useAuth } from '../../contexts/AuthContext';
import TextField from '@mui/material/TextField';
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, addDoc, collection, Timestamp } from "firebase/firestore";


const modalStylePassed = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #2e7d32',
    boxShadow: 24,
    p: 4,
    color: '#2e7d32'
  };

const firebaseConfig = {
    apiKey: "AIzaSyAMSHGM207WyPb2eT5UbUE4N5lml-MlsQE",
    authDomain: "dengrco.firebaseapp.com",
    projectId: "dengrco",
    storageBucket: "dengrco.appspot.com",
    messagingSenderId: "970698864083",
    appId: "1:970698864083:web:926f111e7e16a842ecab26",
    measurementId: "G-7B0Y9GFLHH"  
  };

const app = initializeApp(firebaseConfig);
const fs = getFirestore(app);

const CancelSubscriptionModal = ({openCancelModal, setOpenCancelModal}) => {
  const { currentUser } = useAuth();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleOpen = () => {
    setOpenCancelModal(true);
  };
  const handleClose = () => {
    setOpenCancelModal(false);
  };

  async function cancelSub() {

    const intent = await fetch("https://us-west3-dengrco.cloudfunctions.net/cancel-subscription", {
      method: "POST",
      body: JSON.stringify({"user": currentUser.email})
    });

    return intent;
  } 


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const result = await cancelSub();
      alert('Your subscription has been cancelled. You will have access to the course until the next billing date, and will not get billed during your subscription renewal.');
      handleClose();
    } catch(err) {
        alert("Sorry, we're unable to process the subscription cancellation at this time. Please try again later or send us a message with the Contact Support button.")
        handleClose();
        //TODO: log these errors to backend
      }
    }

    return (
      <>
        <Modal
          open={openCancelModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 600, color: 'black'}} component="form" noValidate onSubmit={handleSubmit}>
            <h2 id="child-modal-title">Cancel Subscription</h2>
            <p id="child-modal-description" sx={{color: 'black'}}>
              Are you sure you want to cancel?  
            </p>
            <Button type="submit" variant='contained' sx={{marginTop: 4}}>Yes, Cancel</Button>
            <Button onClick={handleClose} color='error' sx={{marginTop: 4, marginLeft: 5}}>No, I changed my mind</Button>
          </Box>
        </Modal>
      </>
    )
}

const ContactSupportModal = ({openCSModal, setOpenCSModal}) => {
  const { currentUser } = useAuth();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleOpen = () => {
    setOpenCSModal(true);
  };
  const handleClose = () => {
    setOpenCSModal(false);
  };

  async function submitFeedback(name, message) {
    const feedback = {
        "name": name,
        "email": currentUser.email,
        "message": message,
        "date_received": Timestamp.fromDate(new Date())
    }
    addDoc(collection(fs, 'feedback'), feedback);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const result = await submitFeedback(data.get('name'), data.get('message'));
      alert('Your message has been sent to us. Thank you.');
      handleClose();
    } catch(err) {
        alert("Sorry, we're unable to send a support message at this time. Please try again later or send us a message using our contact form on the landing page.")
        handleClose();
        //TODO: log these errors to backend
      }
    }

    return (
      <>
        <Modal
          open={openCSModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 600, color: 'black' }} component="form" noValidate onSubmit={handleSubmit}>
            <h2 id="child-modal-title">Contact Support</h2>
            <p id="child-modal-description">
              What's on your mind? We'll try to get back to you within a few hours. 
            </p>
            <TextField
                  required
                  fullWidth
                  size='medium'
                  id="name"
                  label="Your name"
                  name="name"
                  autoComplete="name"
                />
            <TextField
                  required
                  fullWidth
                  size='medium'
                  id="message"
                  label="Message"
                  name="message"
                  autoComplete="message"
                  sx={{marginTop: 5}}
                />
            <Button type="submit" variant='contained' sx={{marginTop: 4}}>Send Message</Button>
            <Button onClick={handleClose} color='error' sx={{marginTop: 4, marginLeft: 5}}>Cancel</Button>
          </Box>
        </Modal>
      </>
    )
}

const ResetPasswordModal = ({openRSModal, setOpenRSModal}) => {
    const { currentUser, resetPassword } = useAuth();
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
      textAlign: 'center'
    };
  
    const handleOpen = () => {
      setOpenRSModal(true);
    };
    const handleClose = () => {
      setOpenRSModal(false);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      try {
        const result = await resetPassword(currentUser.email);
        alert('A reset link has been sent to your email address. Please check your junk or spam folder if its not in your inbox. Contact support if it has not shown up within 10 minutes.');
        handleClose();
      } catch(err) {
        if (err["code"] && err["code"] == 'auth/wrong-password') {
          alert("Your password is incorrect. Please try again or reset your password using the link below.")
        } else if (err["code"] && err["code"] == 'auth/user-not-found') {
          alert("We weren't able to find your email. Please provide another or sign up if you haven't yet.")
        } else if (err["code"] && err["code"] == 'auth/invalid-email') {
          alert("Invalid email. Please use a differet one.")
        } else if (err["code"] && err["code"] == 'auth/missing-email') {
          alert("The email field is missing. Please provide a valid email address.")
        } else {
          alert("Sorry, we're unable to send you a password reset link at this time. Please try again later or send us a message using our contact form.")
          //TODO: log these errors to backend
        }
      }
    };
  
  
    return (
      <>
        <Modal
          open={openRSModal}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 600 }} component="form" noValidate onSubmit={handleSubmit}>
            <h2 id="child-modal-title">Reset Password</h2>
            <p id="child-modal-description">
              Send a reset password link to this email: {currentUser.email}  
            </p>
            <Button type="submit" variant='contained' sx={{marginTop: 4}}>Send Reset Link</Button>
            <Button onClick={handleClose} color='error' sx={{marginTop: 4, marginLeft: 5}}>Cancel</Button>
          </Box>
        </Modal>
      </>
    )
  }

export default function Account({open, setOpen}) {
    // I can assume user is signed in in order to get to this menu. Might want to revisit that assumption in the future.
    const defaultUser = {'planType': 'n/a', 'nextBillingAmount': 'n/a', 'nextBillingDate': 'n/a'}
    const [finishedModal, setFinishedModal] = useState(false);
    const [userInfo, setUserInfo] = useState(defaultUser);
    const [openRSModal, setOpenRSModal] = useState(false);
    const [openCSModal, setOpenCSModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const { currentUser, getAccountDetails, isSubscribed } = useAuth();
    const [userSubscribed, setUserSubscribed] = useState('false');

    const handleClose = () => {
        setOpenRSModal(false);
        setOpenCSModal(false);
        setOpen(false);
    }

    const resetPassword = () => {
      setOpenRSModal(true);
    }

    const contactSupport = () => {
      setOpenCSModal(true);
    }

    const cancelSubscription = () => {
      setOpenCancelModal(true);
    }

    useEffect(() => {
      const populateUserInfo = async () => {
        const accountDetails = await getAccountDetails();
        setUserInfo(accountDetails);
      }
      populateUserInfo();
      const setSub = async () => {
        const userSub = await isSubscribed();
        setUserSubscribed(userSub);
      }
      setSub();
    }, []);

    return (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{textAlign: 'center'}} id="alert-dialog-title">
              Account Details
            </DialogTitle>
            <DialogContent>
            <List component="nav" aria-label="account details">
                <ListItem>
                  <ListItemText primary="Email" />
                  <ListItemText sx={{textAlign: 'right', fontWeight: 'bold', marginLeft: 5}} primary={currentUser.email} />
                </ListItem>
                <Divider />
                <ListItem divider>
                  <ListItemText primary="Current Plan" />
                  <ListItemText sx={{textAlign: 'right', fontWeight: 'bold'}} primary={userInfo['planType']} />
                </ListItem>
                <ListItem >
                  <ListItemText primary="Next Billing Date" />
                  <ListItemText sx={{textAlign: 'right', fontWeight: 'bold', marginLeft: 5}} primary={userInfo['nextBillingDate']} />
                </ListItem>
                <Divider light />
                <ListItem >
                  <ListItemText primary="Next Billing Amount" /> 
                  <ListItemText sx={{textAlign: 'right', fontWeight: 'bold'}} primary={userInfo['nextBillingAmount']} />
                </ListItem>
            </List>
            { userSubscribed ? <Button sx={{ color: 'text.primary' }} onClick={cancelSubscription}>Cancel Subscription</Button> : ""}
            <Box sx={{textAlign: 'center', marginTop: 2}}>
            <Button onClick={resetPassword}>Reset Password</Button>
            <br />
            <Button onClick={contactSupport}>Contact Support</Button>
            </Box>
            </DialogContent>
            <DialogActions  sx={{ pt: 0, justifyContent: 'center' }}>
              <Button color="error" onClick={handleClose} autoFocus>
                Exit
              </Button>
            </DialogActions>
          </Dialog>
          <Modal
    open={finishedModal}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={modalStylePassed}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Foo
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Bar
      </Typography>
      <Button color="error" onClick={handleClose} autoFocus>
                Exit
              </Button>
      
    </Box>
  </Modal>
  <ResetPasswordModal openRSModal={openRSModal} setOpenRSModal={setOpenRSModal} />
  <ContactSupportModal openCSModal={openCSModal} setOpenCSModal={setOpenCSModal} />
  <CancelSubscriptionModal openCancelModal={openCancelModal} setOpenCancelModal={setOpenCancelModal} />
        </div>
      );
}