import StripeCheckout from "./StripeCheckout";
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements,
  } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_test_cAXKzTcflOBDBqlK7EYPSfFs');

export default function Checkout() {
    return (
        <Elements stripe={stripePromise}>
            <StripeCheckout />
        </Elements>
      )
}