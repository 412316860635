import logo from './logo.svg';
import './App.css';
import './components/form/Quiz'
//import Navbar from './components/ui/Navbar';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//import PrivateRoute from './components/ui/PrivateRoute';
import UpdateProfile from './pages/UpdateProfile';
import SectionBar from './components/ui/Sectionbar';

function App() {
  return (
    <div className="App">
          <Router>
            <AuthProvider>
              <Dashboard />
            </AuthProvider>
          </Router>
    </div>
  );
} 

export default App;

/*
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<PrivateRoute><Dashboard/></PrivateRoute>} />
              <Route path="/update-profile" element={<PrivateRoute><UpdateProfile/></PrivateRoute>} />          
              <Route path="/signup" element={<Signup/>} />
              <Route path="/login" element={<Login/>} />
            </Routes>
          </AuthProvider>
        </Router>
*/

/*
<PrivateRoute exact path="/" component={Dashboard} />
<Navbar />
*/

/*
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        */
