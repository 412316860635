import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext'
//import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Modal } from '@mui/material';
import { useState } from 'react';


export default function Signup({openSignup, setOpenSignup, setOpenLogin}) {
  const { signUp } = useAuth()
  //const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpenSignup(false) };
  //let navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('password') !== data.get('confirm-password')) {
      alert("Passwords don't match. Please try again.");
      return;
    }
    try {
      const result = await signUp(data.get('email'), data.get('password'));
      setOpenSignup(false);
    } catch (err) {
      if (err["code"] && err["code"] == 'auth/email-already-in-use') {
        alert("This email is already in use. Please use another or login with it.")
      } else if (err["code"] && err["code"] == 'auth/weak-password') {
        alert("Your password does not meet these requirements: \n" + err.message)
      } else if (err["code"] && err["code"] == 'auth/invalid-email') {
        alert("Invalid email. Please use a differet one.")
      } else {
        alert("Sorry - Sign Up is currently not working. Please try again later or send us a message using our contact form.")
        //TODO: log these errors to backend
      }
    }
  };

  const handleLogin = () => {
    setOpenSignup(false);
    setOpenLogin(true);
  }

  return (
    <Modal
  open={openSignup}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Card>
    <CardContent>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  id="confirm-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link onClick={handleLogin} variant="body2" sx={{ 'cursor': 'pointer'}}>
                  Already have an account? Login here.
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      </CardContent>
      </Card>
      </Modal>
  );
}