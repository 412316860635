import { createContext, useContext, useEffect, useState } from 'react'
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";

//https://stackoverflow.com/questions/68104551/react-firebase-authentication-and-usecontext
//https://www.youtube.com/watch?v=PKwu15ldZ7k&t=1428s&ab_channel=WebDevSimplified

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const firebaseConfig = {
    apiKey: "AIzaSyAMSHGM207WyPb2eT5UbUE4N5lml-MlsQE",
    authDomain: "dengrco.firebaseapp.com",
    projectId: "dengrco",
    storageBucket: "dengrco.appspot.com",
    messagingSenderId: "970698864083",
    appId: "1:970698864083:web:926f111e7e16a842ecab26",
    measurementId: "G-7B0Y9GFLHH"  
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const fs = getFirestore(app);

  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function signOut() {
    return auth.signOut();
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function getUser() {
    return auth.currentUser
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  async function isSubscribed() {
    if (!currentUser) {
      return false
    }
    const user = auth.currentUser.uid; 
    const userInfo = await getDoc(doc(fs, 'app', 'users', user, 'userInfo'));
    if (userInfo.data() && userInfo.data()['subscribed'] && (userInfo.data()['subscribed'] === true)) {
      return true
    } else {
      return false
    }
  }

  async function getAccountDetails() {
    if (!currentUser) {
      return false
    }
    const user = auth.currentUser.uid; 
    const userInfo = await getDoc(doc(fs, 'app', 'users', user, 'userInfo'));
    if (userInfo.data() && userInfo.data()['subscribed']) {
      return {'planType': userInfo.data()['planType'], 'nextBillingAmount': userInfo.data()['nextBillingAmount'], 
              'nextBillingDate': userInfo.data()['nextBillingDate']}
    } else {
      return {'planType': 'n/a', 'nextBillingAmount': 'n/a', 'nextBillingDate': 'n/a'}
    }
  }

  function isAdmin() {
    return auth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (!!idTokenResult.claims.admin) {
        return true
      } else {
        return false
      }
    })
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    getUser,
    login,
    signOut,
    signUp,
    fs,
    resetPassword,
    isSubscribed,
    getAccountDetails
  }

  return (
    <AuthContext.Provider value={value}>
      { !loading && children }
    </AuthContext.Provider>
  )

}