import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { forwardRef, useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FormGroup, FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../contexts/AuthContext';
import LoginIcon from '@mui/icons-material/Login';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';  

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoggedInIcons = ({handleClose, setOpenSnackBar}) => {
  const { isSubscribed } = useAuth()
  const [userSubscribed, setUserSubscribed] = useState('false');

  useEffect(() => {
    const setSub = async () => {
      const userSub = await isSubscribed();
      setUserSubscribed(userSub);
    }
    setSub();
  }, []);

  const account = () => {
    return handleClose('account');
  }

  const subscribe = () => {
    return handleClose('subscribe');
  }

  const logout = () => {
    setOpenSnackBar(true);
    return handleClose('logout');
  }

  return (
    <>{/* need the strict equality operator here because javascript */}{ (userSubscribed === true) ? 
      <MenuItem onClick={account}><AccountBalanceIcon style={{ marginRight: 5 }} />Account</MenuItem>
      : <><MenuItem onClick={account}><AccountBalanceIcon style={{ marginRight: 5 }} />Account</MenuItem>
      <Divider light />
      <MenuItem onClick={subscribe}><PersonAddAltIcon style={{ marginRight: 5 }} />Subscribe</MenuItem> </>}
      <Divider light />
      <MenuItem onClick={logout}><LogoutIcon style={{ marginRight: 5 }} />Logout</MenuItem>
    </>
  )
}

const NotLoggedInIcons = ({handleClose}) => {
  const signup = () => {
    return handleClose('signup')
  }

  const login = () => {
    return handleClose('login')
  }

  return (
    <>
      <MenuItem onClick={signup}><LockOpenIcon style={{ marginRight: 5}} />Sign Up</MenuItem>
      <Divider light />
      <MenuItem onClick={login}><LoginIcon style={{ marginRight: 5}} />Log In</MenuItem>
    </>
  )
}

const LoginButtons = ({handleClose, setOpenSnackBar}) => {
  const { currentUser } = useAuth();
  if (currentUser) {
    return (<LoggedInIcons handleClose={handleClose} setOpenSnackBar={setOpenSnackBar} />)
  } else {
    return (<NotLoggedInIcons handleClose={handleClose} />)
  }
}

/* TODO: pass these in as props */
const Navbar = ({setHeight, setDarkmode, setOpenSignup, setOpenLogin, setOpenSubscribe, setOpenAccount, darkModeChecked, setDarkModeChecked}) => {
    const { signOut } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
      const updateWindowDimensions = () => {
        const newHeight = window.innerHeight;
        setHeight(newHeight);
        //do i need callback here?
      };
      window.addEventListener("resize", updateWindowDimensions);
      return () => window.removeEventListener("resize", updateWindowDimensions) 
    });

    const handleChange = (event) => {
      let newDM = event.target.checked ? 'dark' : 'light';
      setDarkModeChecked(event.target.checked);
      setDarkmode(newDM);
    };  
  
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = (next) => {
      if (next) {
        if (next === 'signup') {
          //can't use "openSignup" since useState is async
          setOpenSignup(true);
        }
        if (next === 'account') {
          setOpenAccount(true);
        }
        if (next === 'subscribe') {
          setOpenSubscribe(true);
        }
        if (next === 'login') {
          setOpenLogin(true);
        }
        if (next === 'logout') {
          signOut();
        }
      }
      setAnchorEl(null);
    };

    const handleCloseSnackBar = () => {
      setOpenSnackBar(false);
    }

    return (
    <AppBar >
      <Stack direction="row" justifyContent="end">
        <Toolbar >
        
          <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={darkModeChecked}
                onChange={handleChange}
                aria-label="darkmode switch"
              />
            }
            label={darkModeChecked ? 'Light Mode' : 'Dark Mode'}
          />
          </FormGroup>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <LoginButtons handleClose={handleClose} setOpenSnackBar={setOpenSnackBar}/>
              </Menu>
            </div>
            </Toolbar>
            </Stack>
          <Snackbar
              open={openSnackBar}
              autoHideDuration={4000}
              onClose={handleCloseSnackBar}
            ><Alert onClose={handleCloseSnackBar} severity="info" sx={{ width: '100%' }}>
              You've been successfully logged out. 
             </Alert>
         </Snackbar>
    </AppBar>
    )
};

export default Navbar;