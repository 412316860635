import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { getDoc, doc } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import HardwareIcon from '@mui/icons-material/Hardware';
import { Typography } from '@mui/material';

const topics = require('../../resources/topics.json')
//probably won't use unless multiple free subtopics per topic: 
//  const freetopics = require('../../resources/freetopics.json')
const freesubtopics = []
for (const key in topics) {
  //first subtopic of every topic
  freesubtopics.push(topics[key][0])
}
//all subtopics of first topic
freesubtopics.push(...topics["Introduction"])
//remove dupes
const unique_freesubtopics = [...new Set(freesubtopics)]
const drawerWidth = 290;
const container = window !== undefined ? () => window.document.body : undefined;

const SimpleDialog = ({openDialog, setOpenDialog, setOpenSignup}) => {
  const signup = () => { 
    setOpenDialog(false);
    setOpenSignup(true);
  }

  const closeDialog = () => {
    setOpenDialog(false)
  }

  return (
    <Dialog onClose={closeDialog} open={openDialog}>
      <div style={{ justifyContent: 'center', display: 'flex', marginTop: '1rem'}}>
      <Avatar sx={{ m: 1 }}>
      <LockPersonIcon />
          </Avatar> 
      </div>
      <DialogTitle>This content is only available to subscribers. Please login or signup to continue.</DialogTitle>
      <Button onClick={signup}>Signup</Button>
      <Button onClick={closeDialog} color="error">Cancel</Button>
    </Dialog>
  );
}

const SubDialog = ({openDialog, setOpenDialog, setOpenSubscribe}) => {
  const subscribe = () => { 
    setOpenDialog(false);
    setOpenSubscribe(true);
  }

  const closeDialog = () => {
    setOpenDialog(false);
  }

  return (
    <Dialog onClose={closeDialog} open={openDialog}>
      <div style={{ justifyContent: 'center', display: 'flex', marginTop: '1rem'}}>
      <Avatar sx={{ m: 1 }}>
      <LockPersonIcon />
          </Avatar> 
      </div>
      <DialogTitle>This content is only available to users with an active subscription. Please subscribe to continue.</DialogTitle>
      <Button onClick={subscribe}>Subscribe</Button>
      <Button onClick={closeDialog} color="error">Cancel</Button>
    </Dialog>
  );
}

const InnerToolbar = ({navbarHeight, quizCallBack, contentType, setContentType, setOpenSignup, setOpenSubscribe}) => {
    const sectionList = ['Introduction', 'Programming Languages', 'Ingesting Data', 
    'Storing Data', 'Transforming Data', 'Analyzing Data', 'Exchanging Data',
    'Governing Data', 'Architecting Data Systems', 'DataOps', 
    'Machine Learning', 'Project Management', 'Soft Skills']
    const [activeTree, setActiveTree] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [openSubDialog, setOpenSubDialog] = useState(false);
    const [completedTopics, setCompletedTopics] = useState([]);
    const { currentUser, isSubscribed } = useAuth();
    const [userSubscribed, setUserSubscribed] = useState('false');

    const firebaseConfig = {
      apiKey: "AIzaSyAMSHGM207WyPb2eT5UbUE4N5lml-MlsQE",
      authDomain: "dengrco.firebaseapp.com",
      projectId: "dengrco",
      storageBucket: "dengrco.appspot.com",
      messagingSenderId: "970698864083",
      appId: "1:970698864083:web:926f111e7e16a842ecab26",
      measurementId: "G-7B0Y9GFLHH"  
    };
  
    const app = initializeApp(firebaseConfig);
    const fs = getFirestore(app);

    //how i passed in navbarheight from child -> parent -> different child component: https://stackoverflow.com/questions/72827627/get-a-state-from-another-component

    const handleSelect = (event, selectedTree) => {
        //open the "please sign up" if unsigned user and can't access
        if (!currentUser && !(unique_freesubtopics.includes(selectedTree)) && !(sectionList.includes(selectedTree)) && !(selectedTree == "Introduction_kc") && !(selectedTree == "Introduction_as")) {
          setOpenDialog(true);
          return;
        }  

        //open the "please subscribe" box if signed in user isn't subbed
        if (currentUser && !(userSubscribed)  && !(unique_freesubtopics.includes(selectedTree)) && !(sectionList.includes(selectedTree)) && !(selectedTree == "Introduction_kc") && !(selectedTree == "Introduction_kc")) {
          setOpenSubDialog(true);
          return;
        }

        //check to see if knowledge check/quiz
        if (selectedTree.includes('_kc')) {
          quizCallBack(selectedTree.split('_kc')[0]);
          return;
        }

        if (selectedTree.includes('_as')) {
          // TODO: add scroll to selectedTree.split('_as')[0]
          if (contentType !== 'AK') {
            setContentType('AK');
          }
          setActiveTree(selectedTree);
          return;
        }

        // should only ever get down here if element is a non kc/as one and user is subbed
        if (contentType !== 'reading') {
          setContentType('reading');
        }

        setActiveTree(selectedTree);
    }

    const getCompletedTopics = async () => {
      if (currentUser) {
        const user = currentUser.uid; 
        const cTopics = await getDoc(doc(fs, 'app', 'users', user, 'quizProgress'));
        if (cTopics.data() && cTopics.data()['topics']) {
          for (const t in cTopics.data()['topics']) {
            setCompletedTopics(completedTopics => [...completedTopics, cTopics.data()['topics'][t]]);
          }
        }
      }
    }

    useEffect(() => {
      getCompletedTopics();
      const setSub = async () => {
        const userSub = await isSubscribed();
        setUserSubscribed(userSub);
      }
      setSub();
      const element = document.getElementById(activeTree);
      console.log('ct is ' + contentType)
      console.log('at is ' + activeTree)
      console.log('e is ' + element)
      if (element) {
        //need to reset as it changes overtime
        window.scrollTo({top: 0});
        //gave up trying to use navbarheight/yoffset. I think it doesn't translate to y coord, not sure how to do that. hardcoded to 80
        const y = element.getBoundingClientRect().top - 80;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }, [activeTree]);

    const topicColor = (topic) => {
      if (completedTopics.includes(topic)) {
        return 'success.main';
      } else {
        return 'primary';
      }
    }

    return (
      <>
        <Toolbar />
        <SimpleDialog openDialog={openDialog} setOpenDialog={setOpenDialog} setOpenSignup={setOpenSignup} />
        <SubDialog openDialog={openSubDialog} setOpenDialog={setOpenSubDialog} setOpenSubscribe={setOpenSubscribe} />
        <TreeView
            aria-label="section navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto', textAlign: 'left'}}
            onNodeSelect={handleSelect}
        >
          {/* when a quiz gets completed, topic title turns green */}
            {sectionList.map((text, index) => (<>
                <TreeItem nodeId={text} label={<><Box sx={{ fontSize: '1.3rem'}} >{text}</Box></>} sx={{ color: topicColor(text) }}>
                    {/* handle nested subtopics. if free user and not avaialable, display gray*/}
                    {/* TODO: make a sub component for these tree items and pass currentuser, usersub and freetopics as props, to do the ternary operator there*/}
                    {topics[text].map((t, i) => ( <> {((currentUser && userSubscribed) || unique_freesubtopics.includes(t)) ? 
                        <TreeItem nodeId={t} label={<><Box><MenuBookIcon sx={{marginRight: 1, fontSize: 'large'}}/>{t}</Box></>} sx={{color: 'primary'}}/> : 
                        <TreeItem nodeId={t} label={<><Box><MenuBookIcon sx={{marginRight: 1, fontSize: 'large'}}/>{t}</Box></>} sx={{color: '#919090'}}/>}
                        </>
                    ))}
                    {/* add quizzes and project if user is signed in */}
                      {((currentUser && userSubscribed) || text == 'Introduction' )
                        ? <><TreeItem nodeId={text+'_kc'} label={<><Box><SchoolIcon sx={{marginRight: 1,fontSize: 'large'}}/>Knowledge Check</Box></>}  sx={{ color: 'primary'}} /> 
                          <TreeItem nodeId={text+'_as'} label={<><Box><HardwareIcon sx={{marginRight: 1,fontSize: 'large'}}/>Applied Skills</Box></>}  sx={{ color: 'primary'}} /></>
                        : <> <TreeItem nodeId={text+'_kc'} label={<><Box><SchoolIcon sx={{marginRight: 1,fontSize: 'large'}}/>Knowledge Check</Box></>} sx={{color: '#919090'}}/>
                          <TreeItem nodeId={text+'_as'} label={<><Box><HardwareIcon sx={{marginRight: 1,fontSize: 'large'}}/>Applied Skills</Box></>} sx={{color: '#919090'}}/></>}    
                </TreeItem></>
            ))}
        </TreeView>
      </>
    )
}

export default function Sectionbar({navbarHeight, quizCallBack, contentType, setContentType, setOpenSignup, setOpenSubscribe}) {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
    
    return (
      <Box sx={{ display: 'flex', width: 500}}>
        <CssBaseline />
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            zIndex: 1
          }}
        >
        <InnerToolbar navbarHeight={navbarHeight} quizCallBack={quizCallBack} contentType={contentType} setContentType={setContentType} setOpenSignup={setOpenSignup} setOpenSubscribe={setOpenSubscribe}/>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'block', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            zIndex: 1
          }}
          open
        >
        <InnerToolbar navbarHeight={navbarHeight} quizCallBack={quizCallBack} contentType={contentType} setContentType={setContentType} setOpenSignup={setOpenSignup} setOpenSubscribe={setOpenSubscribe}/>
        </Drawer>
      </Box>
    );
  }

/*
    <List>
          {['Introduction', 'Programming Languages', 'Ingesting Data', 
          'Storing Data', 'Transforming Data', 'Analyzing Data', 'Exchanging Data',
          'Governing Data', 'Architecting Data Systems', 'DataOps', 
          'Machine Learning', 'Software Project Management', 'Soft Skills'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
    </List>
            */