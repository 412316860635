import React from 'react'
import freeContent from '../resources/DECourseContentFree.js'
import paidContent from '../resources/DECourseContentPaid.js'
import freeAK from '../resources/DECourseAK/DECourseAKFree.js'
import paidAK from '../resources/DECourseAK/DECourseAKPaid.js'
import { useAuth } from '../contexts/AuthContext'
import { Interweave } from 'interweave'
import { ScopedCssBaseline, Typography } from '@mui/material' 
import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react'

const nestedTheme = createTheme({
  components: {
    // Name of the component
    Interweave: {
      root: {
        // The props to change the default for.
        color: 'red'
      },
    },
  },
});

export const MainContent = ({contentType}) => {
  const { currentUser } = useAuth();
  let htmlContent = currentUser ? paidContent : freeContent;
  if (contentType === 'AK') {
    htmlContent = currentUser ? paidAK : freeContent;
  } 
  return (
    <ThemeProvider theme={nestedTheme}>
      <Interweave content={htmlContent} allowElements allowAttributes />
    </ThemeProvider>
  )
}

/*
<Routes>
<Route path="/paid-content" element={<PrivateRoute><PaidContent/></PrivateRoute>} />          
<Route path="/" element={<FreeContent/>} />
</Routes>
*/